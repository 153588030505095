<body class="h-screen flex justify-center items-center">
    <div class="text-center">
        <img src="../../../../assets/images/branding/YSC-logo.png" alt="Logo" class="mb-4 mx-auto">
        <p class="text-h6 m-6">Log in to your Admin Dashboard</p>
        <form [formGroup]="loginForm" (ngSubmit)="onLoginClicked()" class="space-y-4 text-start">
            <div class="flex flex-col gap-2">
                <app-outline-text-input formControlName="username" [label]="'Email'"
                    placeholder="Ex: john.bravo@company.com" class="w-full" [fullWidth]="true"></app-outline-text-input>
                <app-outline-text-input [type]="'password'" formControlName="password" [label]="'Password'"
                    placeholder="*********" class="w-full" [fullWidth]="true"></app-outline-text-input>
                <div class="text-left text-body2 text-info-500 py-2 cursor-pointer" (click)="onForgotPasswordClicked()" >
                    Forgot Password?
                </div>
            </div>
            <app-button [fullWidth]="true" type="submit" [loading]="loginState.loading()">Login</app-button>
        </form>
    </div>
</body>
