<!--<div class="app-container flex justify-center items-center">-->
<!--  <app-no-data message="DEVELOPMENT IS IN PROGRESS"></app-no-data>-->
<!--</div>-->


<div class="app-container !p-0">
  <div class="app-header">
    <app-outline-text-input [iconSrc]="'assets/icons/magnify.svg'" placeholder="Search..." type="search"
                            [fullWidth]="false"></app-outline-text-input>
  </div>
  <app-data-table
    class="app-table"
    [state]="companyListState"
    [columnDefs]="columnDefs"
    (onActionClicked)="onActionClicked($event)"
    (tableStateChanged)="getCompanyList($event)">
  </app-data-table>

</div>
