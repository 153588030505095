import {Component, ElementRef, inject, signal} from '@angular/core';
import {AppSvgIconComponent} from '../../../../../shared/components/app-svg-icon/app-svg-icon.component';
import {RouterLink} from '@angular/router';
import {CommonModule} from '@angular/common';
import {SidebarMenuComponent, SideMenuItem} from "./sidebar-menu/sidebar-menu.component";
import {LayoutService} from '../../../layout.service';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [AppSvgIconComponent,  CommonModule, SidebarMenuComponent],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {

  layoutService = inject(LayoutService);

  menus = signal<SideMenuItem[]>([
    // {
    //   iconPath: "assets/icons/dashboard.svg",
    //   link: "/dashboard",
    //   label: "Dashboard",
    // },
    {
      iconPath: "assets/icons/domain.svg",
      link: "/companies",
      label: "Companies",
    },
    {
      iconPath: "assets/icons/new-application.svg",
      link: "/company-applications",
      label: "New Applications",
    },
  ]);

  settingsMenu = signal<SideMenuItem>({
    iconPath: "assets/icons/settings.svg",
    link: "/company-settings",
    label: "Settings",
  });

  toggleSidebar() {
    this.layoutService.toggleSidebar();
  }

}
