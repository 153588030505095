import {Component, computed, inject, input, OnDestroy, OnInit, signal} from '@angular/core';
import {AppSvgIconComponent} from '../../../../../../shared/components/app-svg-icon/app-svg-icon.component';
import {NavigationEnd, Router, RouterLink} from "@angular/router";
import {BaseComponent} from '../../../../../../shared/base/base-component';
import {Subscription} from "rxjs";
import {filter} from "rxjs/operators";
import {NgClass} from "@angular/common";
import {TooltipDirective} from '../../../../../../shared/components/tooltip/tooltip.directive';
import {MatTooltip} from "@angular/material/tooltip";
import {TooltipPosition} from '../../../../../../shared/components/tooltip/tooltip.enums';
import {LayoutService} from '../../../../layout.service';

@Component({
  selector: 'app-sidebar-menu',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    NgClass,
    MatTooltip,
    AppSvgIconComponent
  ],
  templateUrl: './sidebar-menu.component.html',
  styleUrl: './sidebar-menu.component.scss'
})
export class SidebarMenuComponent extends BaseComponent implements OnInit, OnDestroy {

  layoutService = inject(LayoutService);

  activeRoute = signal(this.router.url);
  private routeSubscription!: Subscription;
  menuItem = input.required<SideMenuItem>();

  isActive = computed(() => {
    return this.activeRoute() === this.menuItem().link;
  });

  ngOnInit(): void {
    this.routeSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.activeRoute.set(this.router.url);
      });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  onMenuClick() {
    this.router.navigate([this.menuItem().link]);
  }

  protected readonly TooltipPosition = TooltipPosition;
}


export interface SideMenuItem {
  iconPath: string;
  link: string;
  label: string;
}
