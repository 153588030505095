import { Component } from '@angular/core';
import { SidebarComponent } from './components/topbar/sidebar/sidebar.component';
import { TopBarComponent } from './components/topbar/top-bar.component';
import {RouterOutlet} from "@angular/router";
import { SignupApplicationsComponent } from "../signup-applications/signup-applications.component";

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    SidebarComponent,
    TopBarComponent,
    RouterOutlet
],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {

}
