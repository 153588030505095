import {Component, inject} from '@angular/core';
import {BaseComponent} from "../../../shared/base/base-component";
import {OutlineTextInputComponent} from "../../../shared/inputs/outline-text-input/outline-text-input.component";
import {
  ColumnDef,
  DataTableComponent,
  TableActionEvent, TableStateEvent
} from "../../../shared/components/data-table/data-table.component";
import {
  type GetCompanyDelegationTokenParams,
  type GetCompanyDelegationTokenResponse,
  GetCompanyListItem,
  type GetCompanyListParams,
  GetCompanyListResponse
} from "../../../core/api/model";
import {State} from "../../../shared/base/base-state";
import {ApiService} from "../../../core/api/api.service";
import {environment} from "../../../../environments/environment";
import {EnvironmentType} from "../../../../environments/environment-type";

@Component({
  selector: 'app-company-list',
  standalone: true,
  imports: [
    OutlineTextInputComponent,
    DataTableComponent
  ],
  templateUrl: './company-list.component.html',
  styleUrl: './company-list.component.scss'
})
export class CompanyListComponent extends BaseComponent {

  apiService = inject(ApiService);

  companyListState = new State<GetCompanyListResponse>();
  private companyDelegationTokenState = new State<GetCompanyDelegationTokenResponse>();

  columnDefs: ColumnDef[] = [
    {title: 'Code', type: 'text', displayTemplate: '$code', alignment: 'center'},
    {title: 'Company Name', type: 'text', displayTemplate: '$companyName', alignment: 'center'},
    {title: 'DOT Number', type: 'text', displayTemplate: '$dotNumber', alignment: 'center'},
    {title: 'Contact Name', type: 'text', displayTemplate: '$firstName $lastName', alignment: 'center'},
    {title: 'City', type: 'text', displayTemplate: '$city, $stateId', alignment: 'center'},
    {
      title: 'Status',
      type: 'badge',
      displayTemplate: '$companyStatus',
      alignment: 'center',
      configOfBadge: {
        properties: [
          {
            data: 'Active',
            displayText: 'Active',
            backgroundColorClass: 'bg-success-50',
            borderColorClass: 'border-transparent',
            textColorClass: 'text-success-500'
          },
        ]
      },
    },
    {
      title: 'Actions',
      type: 'actions',
      alignment: 'center',
      configOfActions: {
        iconActions: [
          {
            iconPath: 'assets/icons/login.svg',
            actionType: 'login',
            label: 'Login'
          },
          {
            iconPath: 'assets/icons/eye.svg',
            actionType: 'view',
            label: 'View'
          }
        ]
      }
    }
  ];

  getCompanyList(event: TableStateEvent) {
    let request: GetCompanyListParams = {
      pageNumber: event.paginationEvent?.pageNumber,
      pageSize: event.paginationEvent?.pageSize
    };
    this.executeRequest<GetCompanyListResponse>({
      state: this.companyListState,
      request: this.apiService.getCompanyList(request),
    })
  }

  onActionClicked($event: TableActionEvent) {
    let item: GetCompanyListItem = $event.item;
    switch ($event.actionType) {
      case 'login':
        this.loginAsCompany(item.id);
        break;
      case 'view':
        break;
    }
  }

  private loginAsCompany(id: string) {
    let request: GetCompanyDelegationTokenParams = {
      companyId: id
    };
    this.executeRequest<GetCompanyDelegationTokenResponse>({
      state: this.companyDelegationTokenState,
      request: this.apiService.getCompanyDelegationToken(request),
      onSuccess: response => {
        window.open(`${environment.companyPortalUrl}/company-login?token=${response.data?.token}`, '_blank');
      }
    });
  }
}
