import { environment } from '../../../environments/environment';
/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Api
 * OpenAPI spec version: 1.0
 */
import {
  HttpClient
} from '@angular/common/http'
import type {
  HttpContext,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse as AngularHttpResponse
} from '@angular/common/http'
import {
  Injectable
} from '@angular/core'
import {
  Observable
} from 'rxjs'
import type {
  ActiveCompanyStatusRequest,
  BusinessSubTypeListResponse,
  CompleteCompanyApplicationStatusRequest,
  CompleteCompanyApplicationStatusResponse,
  FileUploadControllerUploadFile201,
  FileUploadControllerUploadFileBody,
  GetBusinessSubTypeListParams,
  GetBusinessTypeListParams,
  GetBusinessTypeListResponse,
  GetCompanyApplicationListParams,
  GetCompanyApplicationListResponse,
  GetCompanyApplicationResponse,
  GetCompanyApplicationStatusListResponse,
  GetCompanyDelegationTokenParams,
  GetCompanyDelegationTokenResponse,
  GetCompanyListParams,
  GetCompanyListResponse,
  GetCompanyStatusListResponse,
  GetCompanyTypeListResponse,
  GetContactUs,
  GetContactUsListParams,
  GetContactUsListResponse,
  GetContactUsStatusListResponse,
  GetCountryListParams,
  GetCountryListResponse,
  GetRequestForDemoListParams,
  GetRequestForDemoListResponse,
  GetRequestForDemoResponse,
  GetRequestForDemoStatusListResponse,
  GetStateListParams,
  GetStateListResponse,
  GetUploadPreSignedResponse,
  GetUserResponse,
  InActiveCompanyStatusRequest,
  LoginRequest,
  LoginResponse,
  PutPreSignedParams,
  RejectCompanyApplicationStatusRequest,
  RejectCompanyApplicationStatusResponse,
  SignOutRequest,
  SignOutResponse,
  UpdateCompanyRequest,
  UpdateCompanyResponse,
  UpdateContactUsRequest,
  UpdateContactUsResponse,
  UpdateContactUsStatus,
  UpdateContactUsStatusRequest,
  UpdateRequestForDemoRequest,
  UpdateRequestForDemoResponse,
  UpdateRequestForDemoStatusRequest,
  UpdateRequestForDemoStatusResponse,
  UploadTempFileRequest,
  UploadTempFileResponse
} from './model'



type HttpClientOptions = {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: any;
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
};



@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    private http: HttpClient,
  ) {}/**
 * Operation ID: getCompanyDelegationToken


 */
 getCompanyDelegationToken<TData = GetCompanyDelegationTokenResponse>(
    params: GetCompanyDelegationTokenParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    getCompanyDelegationToken<TData = GetCompanyDelegationTokenResponse>(
    params: GetCompanyDelegationTokenParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    getCompanyDelegationToken<TData = GetCompanyDelegationTokenResponse>(
    params: GetCompanyDelegationTokenParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;getCompanyDelegationToken<TData = GetCompanyDelegationTokenResponse>(
    params: GetCompanyDelegationTokenParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/admin/company/access/delegation/token`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: GetFileController_uploadFile


 * @summary Get S3 File
 */
 getFileControllerUploadFile<TData = unknown>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    getFileControllerUploadFile<TData = unknown>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    getFileControllerUploadFile<TData = unknown>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;getFileControllerUploadFile<TData = unknown>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/admin/get-file`,options
    );
  }

/**
 * Operation ID: FileUploadController_uploadFile


 * @summary Upload a file with additional data to S3
 */
 fileUploadControllerUploadFile<TData = FileUploadControllerUploadFile201>(
    fileUploadControllerUploadFileBody: FileUploadControllerUploadFileBody, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    fileUploadControllerUploadFile<TData = FileUploadControllerUploadFile201>(
    fileUploadControllerUploadFileBody: FileUploadControllerUploadFileBody, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    fileUploadControllerUploadFile<TData = FileUploadControllerUploadFile201>(
    fileUploadControllerUploadFileBody: FileUploadControllerUploadFileBody, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;fileUploadControllerUploadFile<TData = FileUploadControllerUploadFile201>(
    fileUploadControllerUploadFileBody: FileUploadControllerUploadFileBody, options?: HttpClientOptions
  ): Observable<TData>  {const formData = new FormData();
if(fileUploadControllerUploadFileBody.file !== undefined) {
 formData.append('file', fileUploadControllerUploadFileBody.file)
 }
if(fileUploadControllerUploadFileBody.title !== undefined) {
 formData.append('title', fileUploadControllerUploadFileBody.title)
 }
if(fileUploadControllerUploadFileBody.description !== undefined) {
 formData.append('description', fileUploadControllerUploadFileBody.description)
 }

    return this.http.post<TData>(
      `${environment.baseUrl}/admin/upload`,
      formData,options
    );
  }

/**
 * Operation ID: inActiveCompanyStatus


 */
 inActiveCompanyStatus<TData = void>(
    inActiveCompanyStatusRequest: InActiveCompanyStatusRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    inActiveCompanyStatus<TData = void>(
    inActiveCompanyStatusRequest: InActiveCompanyStatusRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    inActiveCompanyStatus<TData = void>(
    inActiveCompanyStatusRequest: InActiveCompanyStatusRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;inActiveCompanyStatus<TData = void>(
    inActiveCompanyStatusRequest: InActiveCompanyStatusRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/admin/company/status/in-active`,
      inActiveCompanyStatusRequest,options
    );
  }

/**
 * Operation ID: activeCompanyStatus


 */
 activeCompanyStatus<TData = void>(
    activeCompanyStatusRequest: ActiveCompanyStatusRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    activeCompanyStatus<TData = void>(
    activeCompanyStatusRequest: ActiveCompanyStatusRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    activeCompanyStatus<TData = void>(
    activeCompanyStatusRequest: ActiveCompanyStatusRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;activeCompanyStatus<TData = void>(
    activeCompanyStatusRequest: ActiveCompanyStatusRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/admin/company/status/active`,
      activeCompanyStatusRequest,options
    );
  }

/**
 * Operation ID: putPreSigned


 */
 putPreSigned<TData = GetUploadPreSignedResponse>(
    params: PutPreSignedParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    putPreSigned<TData = GetUploadPreSignedResponse>(
    params: PutPreSignedParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    putPreSigned<TData = GetUploadPreSignedResponse>(
    params: PutPreSignedParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;putPreSigned<TData = GetUploadPreSignedResponse>(
    params: PutPreSignedParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/admin/upload/presigned`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: updateCompany


 */
 updateCompany<TData = UpdateCompanyResponse>(
    id: string,
    updateCompanyRequest: UpdateCompanyRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    updateCompany<TData = UpdateCompanyResponse>(
    id: string,
    updateCompanyRequest: UpdateCompanyRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    updateCompany<TData = UpdateCompanyResponse>(
    id: string,
    updateCompanyRequest: UpdateCompanyRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;updateCompany<TData = UpdateCompanyResponse>(
    id: string,
    updateCompanyRequest: UpdateCompanyRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/admin/companies/${id}`,
      updateCompanyRequest,options
    );
  }

/**
 * Operation ID: completeCompanyApplicationStatus


 */
 completeCompanyApplicationStatus<TData = CompleteCompanyApplicationStatusResponse>(
    completeCompanyApplicationStatusRequest: CompleteCompanyApplicationStatusRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    completeCompanyApplicationStatus<TData = CompleteCompanyApplicationStatusResponse>(
    completeCompanyApplicationStatusRequest: CompleteCompanyApplicationStatusRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    completeCompanyApplicationStatus<TData = CompleteCompanyApplicationStatusResponse>(
    completeCompanyApplicationStatusRequest: CompleteCompanyApplicationStatusRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;completeCompanyApplicationStatus<TData = CompleteCompanyApplicationStatusResponse>(
    completeCompanyApplicationStatusRequest: CompleteCompanyApplicationStatusRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/admin/company-application-status/approve`,
      completeCompanyApplicationStatusRequest,options
    );
  }

/**
 * Operation ID: rejectCompanyApplicationStatus


 */
 rejectCompanyApplicationStatus<TData = RejectCompanyApplicationStatusResponse>(
    rejectCompanyApplicationStatusRequest: RejectCompanyApplicationStatusRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    rejectCompanyApplicationStatus<TData = RejectCompanyApplicationStatusResponse>(
    rejectCompanyApplicationStatusRequest: RejectCompanyApplicationStatusRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    rejectCompanyApplicationStatus<TData = RejectCompanyApplicationStatusResponse>(
    rejectCompanyApplicationStatusRequest: RejectCompanyApplicationStatusRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;rejectCompanyApplicationStatus<TData = RejectCompanyApplicationStatusResponse>(
    rejectCompanyApplicationStatusRequest: RejectCompanyApplicationStatusRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/admin/company-application-status/reject`,
      rejectCompanyApplicationStatusRequest,options
    );
  }

/**
 * Operation ID: getCompanyApplication


 */
 getCompanyApplication<TData = GetCompanyApplicationResponse>(
    id: string, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    getCompanyApplication<TData = GetCompanyApplicationResponse>(
    id: string, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    getCompanyApplication<TData = GetCompanyApplicationResponse>(
    id: string, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;getCompanyApplication<TData = GetCompanyApplicationResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/admin/company-applications/${id}`,options
    );
  }

/**
 * Operation ID: getCompanyList


 */
 getCompanyList<TData = GetCompanyListResponse[]>(
    params?: GetCompanyListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    getCompanyList<TData = GetCompanyListResponse[]>(
    params?: GetCompanyListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    getCompanyList<TData = GetCompanyListResponse[]>(
    params?: GetCompanyListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;getCompanyList<TData = GetCompanyListResponse[]>(
    params?: GetCompanyListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/admin/companies`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getCompanyApplicationList


 */
 getCompanyApplicationList<TData = GetCompanyApplicationListResponse[]>(
    params?: GetCompanyApplicationListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    getCompanyApplicationList<TData = GetCompanyApplicationListResponse[]>(
    params?: GetCompanyApplicationListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    getCompanyApplicationList<TData = GetCompanyApplicationListResponse[]>(
    params?: GetCompanyApplicationListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;getCompanyApplicationList<TData = GetCompanyApplicationListResponse[]>(
    params?: GetCompanyApplicationListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/admin/company-applications`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: updateRequestForDemoStatus


 */
 updateRequestForDemoStatus<TData = UpdateRequestForDemoStatusResponse>(
    id: string,
    updateRequestForDemoStatusRequest: UpdateRequestForDemoStatusRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    updateRequestForDemoStatus<TData = UpdateRequestForDemoStatusResponse>(
    id: string,
    updateRequestForDemoStatusRequest: UpdateRequestForDemoStatusRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    updateRequestForDemoStatus<TData = UpdateRequestForDemoStatusResponse>(
    id: string,
    updateRequestForDemoStatusRequest: UpdateRequestForDemoStatusRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;updateRequestForDemoStatus<TData = UpdateRequestForDemoStatusResponse>(
    id: string,
    updateRequestForDemoStatusRequest: UpdateRequestForDemoStatusRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/admin/request-for-demo/update-status/${id}`,
      updateRequestForDemoStatusRequest,options
    );
  }

/**
 * Operation ID: updateContactUsStatus


 */
 updateContactUsStatus<TData = UpdateContactUsStatus>(
    id: string,
    updateContactUsStatusRequest: UpdateContactUsStatusRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    updateContactUsStatus<TData = UpdateContactUsStatus>(
    id: string,
    updateContactUsStatusRequest: UpdateContactUsStatusRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    updateContactUsStatus<TData = UpdateContactUsStatus>(
    id: string,
    updateContactUsStatusRequest: UpdateContactUsStatusRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;updateContactUsStatus<TData = UpdateContactUsStatus>(
    id: string,
    updateContactUsStatusRequest: UpdateContactUsStatusRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/admin/contact-us/update-status/${id}`,
      updateContactUsStatusRequest,options
    );
  }

/**
 * Operation ID: getRequestForDemoList


 */
 getRequestForDemoList<TData = GetRequestForDemoListResponse>(
    params?: GetRequestForDemoListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    getRequestForDemoList<TData = GetRequestForDemoListResponse>(
    params?: GetRequestForDemoListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    getRequestForDemoList<TData = GetRequestForDemoListResponse>(
    params?: GetRequestForDemoListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;getRequestForDemoList<TData = GetRequestForDemoListResponse>(
    params?: GetRequestForDemoListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/admin/request-for-demo`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getRequestForDemo


 */
 getRequestForDemo<TData = GetRequestForDemoResponse>(
    id: string, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    getRequestForDemo<TData = GetRequestForDemoResponse>(
    id: string, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    getRequestForDemo<TData = GetRequestForDemoResponse>(
    id: string, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;getRequestForDemo<TData = GetRequestForDemoResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/admin/request-for-demo/${id}`,options
    );
  }

/**
 * Operation ID: updateRequestForDemo


 */
 updateRequestForDemo<TData = UpdateRequestForDemoResponse>(
    id: string,
    updateRequestForDemoRequest: UpdateRequestForDemoRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    updateRequestForDemo<TData = UpdateRequestForDemoResponse>(
    id: string,
    updateRequestForDemoRequest: UpdateRequestForDemoRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    updateRequestForDemo<TData = UpdateRequestForDemoResponse>(
    id: string,
    updateRequestForDemoRequest: UpdateRequestForDemoRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;updateRequestForDemo<TData = UpdateRequestForDemoResponse>(
    id: string,
    updateRequestForDemoRequest: UpdateRequestForDemoRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/admin/request-for-demo/${id}`,
      updateRequestForDemoRequest,options
    );
  }

/**
 * Operation ID: getRequestForDemoStatusList


 */
 getRequestForDemoStatusList<TData = GetRequestForDemoStatusListResponse>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    getRequestForDemoStatusList<TData = GetRequestForDemoStatusListResponse>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    getRequestForDemoStatusList<TData = GetRequestForDemoStatusListResponse>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;getRequestForDemoStatusList<TData = GetRequestForDemoStatusListResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/admin/request-for-demo-status`,options
    );
  }

/**
 * Operation ID: getContactUsList


 */
 getContactUsList<TData = GetContactUsListResponse>(
    params?: GetContactUsListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    getContactUsList<TData = GetContactUsListResponse>(
    params?: GetContactUsListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    getContactUsList<TData = GetContactUsListResponse>(
    params?: GetContactUsListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;getContactUsList<TData = GetContactUsListResponse>(
    params?: GetContactUsListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/admin/contact-us`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getContactUs


 */
 getContactUs<TData = GetContactUs>(
    id: string, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    getContactUs<TData = GetContactUs>(
    id: string, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    getContactUs<TData = GetContactUs>(
    id: string, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;getContactUs<TData = GetContactUs>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/admin/contact-us/${id}`,options
    );
  }

/**
 * Operation ID: updateContactUs


 */
 updateContactUs<TData = UpdateContactUsResponse>(
    id: string,
    updateContactUsRequest: UpdateContactUsRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    updateContactUs<TData = UpdateContactUsResponse>(
    id: string,
    updateContactUsRequest: UpdateContactUsRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    updateContactUs<TData = UpdateContactUsResponse>(
    id: string,
    updateContactUsRequest: UpdateContactUsRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;updateContactUs<TData = UpdateContactUsResponse>(
    id: string,
    updateContactUsRequest: UpdateContactUsRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/admin/contact-us/${id}`,
      updateContactUsRequest,options
    );
  }

/**
 * Operation ID: getContactUsStatusList


 */
 getContactUsStatusList<TData = GetContactUsStatusListResponse>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    getContactUsStatusList<TData = GetContactUsStatusListResponse>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    getContactUsStatusList<TData = GetContactUsStatusListResponse>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;getContactUsStatusList<TData = GetContactUsStatusListResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/admin/contact-us-status`,options
    );
  }

/**
 * Operation ID: getUserInfo


 */
 getUserInfo<TData = GetUserResponse>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    getUserInfo<TData = GetUserResponse>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    getUserInfo<TData = GetUserResponse>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;getUserInfo<TData = GetUserResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/admin/users/info`,options
    );
  }

/**
 * Operation ID: login


 */
 login<TData = LoginResponse>(
    loginRequest: LoginRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    login<TData = LoginResponse>(
    loginRequest: LoginRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    login<TData = LoginResponse>(
    loginRequest: LoginRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;login<TData = LoginResponse>(
    loginRequest: LoginRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/admin/auth/login`,
      loginRequest,options
    );
  }

/**
 * Operation ID: signOut


 */
 signOut<TData = SignOutResponse>(
    signOutRequest: SignOutRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    signOut<TData = SignOutResponse>(
    signOutRequest: SignOutRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    signOut<TData = SignOutResponse>(
    signOutRequest: SignOutRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;signOut<TData = SignOutResponse>(
    signOutRequest: SignOutRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/admin/auth/sign-out`,
      signOutRequest,options
    );
  }

/**
 * Operation ID: DeleteCompanyApplicationController_execute


 */
 deleteCompanyApplicationControllerExecute<TData = void>(
    id: string, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    deleteCompanyApplicationControllerExecute<TData = void>(
    id: string, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    deleteCompanyApplicationControllerExecute<TData = void>(
    id: string, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;deleteCompanyApplicationControllerExecute<TData = void>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.delete<TData>(
      `${environment.baseUrl}/admin/company-application/${id}`,options
    );
  }

/**
 * Operation ID: uploadTempFile


 */
 uploadTempFile<TData = UploadTempFileResponse>(
    uploadTempFileRequest: UploadTempFileRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    uploadTempFile<TData = UploadTempFileResponse>(
    uploadTempFileRequest: UploadTempFileRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    uploadTempFile<TData = UploadTempFileResponse>(
    uploadTempFileRequest: UploadTempFileRequest, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;uploadTempFile<TData = UploadTempFileResponse>(
    uploadTempFileRequest: UploadTempFileRequest, options?: HttpClientOptions
  ): Observable<TData>  {const formData = new FormData();
formData.append('file', uploadTempFileRequest.file)

    return this.http.put<TData>(
      `${environment.baseUrl}/common/upload/temp`,
      formData,options
    );
  }

/**
 * Operation ID: getBusinessSubTypeList


 */
 getBusinessSubTypeList<TData = BusinessSubTypeListResponse>(
    params?: GetBusinessSubTypeListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    getBusinessSubTypeList<TData = BusinessSubTypeListResponse>(
    params?: GetBusinessSubTypeListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    getBusinessSubTypeList<TData = BusinessSubTypeListResponse>(
    params?: GetBusinessSubTypeListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;getBusinessSubTypeList<TData = BusinessSubTypeListResponse>(
    params?: GetBusinessSubTypeListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/business-sub-types`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getBusinessTypeList


 */
 getBusinessTypeList<TData = GetBusinessTypeListResponse>(
    params?: GetBusinessTypeListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    getBusinessTypeList<TData = GetBusinessTypeListResponse>(
    params?: GetBusinessTypeListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    getBusinessTypeList<TData = GetBusinessTypeListResponse>(
    params?: GetBusinessTypeListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;getBusinessTypeList<TData = GetBusinessTypeListResponse>(
    params?: GetBusinessTypeListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/business-types`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getStateList


 */
 getStateList<TData = GetStateListResponse>(
    params?: GetStateListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    getStateList<TData = GetStateListResponse>(
    params?: GetStateListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    getStateList<TData = GetStateListResponse>(
    params?: GetStateListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;getStateList<TData = GetStateListResponse>(
    params?: GetStateListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/states`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getCountryList


 */
 getCountryList<TData = GetCountryListResponse>(
    params?: GetCountryListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    getCountryList<TData = GetCountryListResponse>(
    params?: GetCountryListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    getCountryList<TData = GetCountryListResponse>(
    params?: GetCountryListParams, options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;getCountryList<TData = GetCountryListResponse>(
    params?: GetCountryListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/countries`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getCompanyTypeList


 */
 getCompanyTypeList<TData = GetCompanyTypeListResponse>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    getCompanyTypeList<TData = GetCompanyTypeListResponse>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    getCompanyTypeList<TData = GetCompanyTypeListResponse>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;getCompanyTypeList<TData = GetCompanyTypeListResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/company/types`,options
    );
  }

/**
 * Operation ID: getCompanyStatusList


 */
 getCompanyStatusList<TData = GetCompanyStatusListResponse>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    getCompanyStatusList<TData = GetCompanyStatusListResponse>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    getCompanyStatusList<TData = GetCompanyStatusListResponse>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;getCompanyStatusList<TData = GetCompanyStatusListResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/company/status`,options
    );
  }

/**
 * Operation ID: getCompanyApplicationStatusList


 */
 getCompanyApplicationStatusList<TData = GetCompanyApplicationStatusListResponse>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' }
  ): Observable<TData>;
    getCompanyApplicationStatusList<TData = GetCompanyApplicationStatusListResponse>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' }
  ): Observable<AngularHttpResponse<TData>>;
    getCompanyApplicationStatusList<TData = GetCompanyApplicationStatusListResponse>(
     options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' }
  ): Observable<HttpEvent<TData>>;getCompanyApplicationStatusList<TData = GetCompanyApplicationStatusListResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/company-application-status`,options
    );
  }

};

export type GetCompanyDelegationTokenClientResult = NonNullable<GetCompanyDelegationTokenResponse>
export type GetFileControllerUploadFileClientResult = NonNullable<unknown>
export type FileUploadControllerUploadFileClientResult = NonNullable<FileUploadControllerUploadFile201>
export type InActiveCompanyStatusClientResult = NonNullable<void>
export type ActiveCompanyStatusClientResult = NonNullable<void>
export type PutPreSignedClientResult = NonNullable<GetUploadPreSignedResponse>
export type UpdateCompanyClientResult = NonNullable<UpdateCompanyResponse>
export type CompleteCompanyApplicationStatusClientResult = NonNullable<CompleteCompanyApplicationStatusResponse>
export type RejectCompanyApplicationStatusClientResult = NonNullable<RejectCompanyApplicationStatusResponse>
export type GetCompanyApplicationClientResult = NonNullable<GetCompanyApplicationResponse>
export type GetCompanyListClientResult = NonNullable<GetCompanyListResponse[]>
export type GetCompanyApplicationListClientResult = NonNullable<GetCompanyApplicationListResponse[]>
export type UpdateRequestForDemoStatusClientResult = NonNullable<UpdateRequestForDemoStatusResponse>
export type UpdateContactUsStatusClientResult = NonNullable<UpdateContactUsStatus>
export type GetRequestForDemoListClientResult = NonNullable<GetRequestForDemoListResponse>
export type GetRequestForDemoClientResult = NonNullable<GetRequestForDemoResponse>
export type UpdateRequestForDemoClientResult = NonNullable<UpdateRequestForDemoResponse>
export type GetRequestForDemoStatusListClientResult = NonNullable<GetRequestForDemoStatusListResponse>
export type GetContactUsListClientResult = NonNullable<GetContactUsListResponse>
export type GetContactUsClientResult = NonNullable<GetContactUs>
export type UpdateContactUsClientResult = NonNullable<UpdateContactUsResponse>
export type GetContactUsStatusListClientResult = NonNullable<GetContactUsStatusListResponse>
export type GetUserInfoClientResult = NonNullable<GetUserResponse>
export type LoginClientResult = NonNullable<LoginResponse>
export type SignOutClientResult = NonNullable<SignOutResponse>
export type DeleteCompanyApplicationControllerExecuteClientResult = NonNullable<void>
export type UploadTempFileClientResult = NonNullable<UploadTempFileResponse>
export type GetBusinessSubTypeListClientResult = NonNullable<BusinessSubTypeListResponse>
export type GetBusinessTypeListClientResult = NonNullable<GetBusinessTypeListResponse>
export type GetStateListClientResult = NonNullable<GetStateListResponse>
export type GetCountryListClientResult = NonNullable<GetCountryListResponse>
export type GetCompanyTypeListClientResult = NonNullable<GetCompanyTypeListResponse>
export type GetCompanyStatusListClientResult = NonNullable<GetCompanyStatusListResponse>
export type GetCompanyApplicationStatusListClientResult = NonNullable<GetCompanyApplicationStatusListResponse>
