import {inject, Injectable, signal} from '@angular/core';
import {AppDataService} from "../../core/services/app-data/app-data.service";

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  appDataService = inject(AppDataService);

  currentMenu = signal<{ label: string; iconPath: string }>({
    label: "Dashboard",
    iconPath: "assets/icons/dashboard.svg",
  });

  private _sidebarExpanded = signal(this.appDataService.isSidebarExpanded());

  get sidebarExpanded() {
    return this._sidebarExpanded();
  }

  public toggleSidebar() {
    this._sidebarExpanded.update((curr) => !curr);
    this.appDataService.toggleSidebar();
  }

  setCurrentMenu(menu: { label: string; iconPath: string }) {
    this.currentMenu.set(menu);
  }
}
