import {Component, Inject, inject, OnInit} from '@angular/core';
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {State} from '../../../shared/base/base-state';
import {BaseComponent} from '../../../shared/base/base-component';
import {AuthService} from '../../../core/services/auth/auth.service';
import {LoginResponse, LoginResponseData} from '../../../core/services/auth/login/login-response';
import {TokenStoreService} from '../../../core/services/token/token-store.service';
import {OutlineTextInputComponent} from "../../../shared/inputs/outline-text-input/outline-text-input.component";

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  imports: [OutlineTextInputComponent, ButtonComponent, ReactiveFormsModule]
})
export class LoginComponent extends BaseComponent implements OnInit {

  authService = inject(AuthService);
  formBuilder = inject(FormBuilder);
  tokenStoreService = inject(TokenStoreService);
  loginForm!: FormGroup;
  loginState = new State<LoginResponse>();

  ngOnInit(): void {
    this.handleLogin();
    this.initLoginForm();
  }

  private initLoginForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  handleLogin() {
    const isLoggedIn = this.tokenStoreService.isLoggedIn();
    if (isLoggedIn) {
      this.router.navigate(['/dashboard']);
    }
  }

  onForgotPasswordClicked() {
    this.router.navigate(['/forgot-password']);
  }

  onLoginClicked() {
    if (this.loginForm.invalid) {
      return;
    }
    let request = this.loginForm.value;
    this.executeRequest<LoginResponse>({
      state: this.loginState,
      request: this.authService.login(request),
      handleSuccess: false,
      onSuccess: response => {
        this.handleLoginSuccess(response.data);
        const name = response.data?.userDetail?.name;
        localStorage.setItem('userName', name);
        this.router.navigate(['/dashboard']);
      },
    });
  }

  private handleLoginSuccess(loginResponse: LoginResponseData) {
    this.loginForm.reset();
    this.tokenStoreService.saveToken(loginResponse.refreshToken, loginResponse.accessToken);
    this.router.navigate(['/dashboard']);
  }
}
