import {Component, Input, inject, input, signal, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppSvgIconComponent} from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {RouterLink} from '@angular/router';
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {TokenStoreService} from "../../../../core/services/token/token-store.service";
import {BaseComponent} from "../../../../shared/base/base-component";

@Component({
  selector: 'app-top-bar',
  standalone: true,
  imports: [
    AppSvgIconComponent, CommonModule,  MatMenu,  MatMenuTrigger
  ],
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.scss'
})
export class TopBarComponent extends BaseComponent implements OnInit {

  @Input() userName?: string;

  tokenService = inject(TokenStoreService);

  onAccountClicked() {
    this.router.navigate(['/profile-settings']);
  }

  onLogoutClick() {
    this.tokenService.logout();
    this.userName = localStorage.removeItem('userName')?? ''
  }

  ngOnInit() {
    this.userName = localStorage.getItem('userName') ?? ''
  }
}

