import {Component, OnInit, inject} from '@angular/core';

import {ButtonComponent} from "../../shared/components/button/button.component";
import {AppSvgIconComponent} from "../../shared/components/app-svg-icon/app-svg-icon.component";
import {State} from '../../shared/base/base-state';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {SignupApplicationService} from "../../core/services/signup-applications/signup-applications.service";
import {
  AcceptSignupApplicationRequest
} from "../../core/services/signup-applications/accept-signup-application/accept-signup-application.request";
import {
  RejectSignupApplicationRequest
} from "../../core/services/signup-applications/reject-signup-application/reject-signup-application.request";
import {GenericResponse} from "../../core/services/generic-response";
import {ApiService} from "../../core/api/api.service";
import type {
  GetCompanyApplicationListItem,
  GetCompanyApplicationListParams,
  GetCompanyApplicationListResponse
} from "../../core/api/model";
import {Constants} from "../../core/constants/constants";
import {OutlineTextInputComponent} from "../../shared/inputs/outline-text-input/outline-text-input.component";
import {BaseComponent} from "../../shared/base/base-component";
import {
  ColumnDef,
  DataTableComponent,
  TableActionEvent,
  TableStateEvent
} from "../../shared/components/data-table/data-table.component";

@Component({
  selector: 'app-signup-applications',
  standalone: true,
  imports: [OutlineTextInputComponent,  MatButtonModule, MatMenuModule, MatIconModule,  CommonModule,   DataTableComponent],
  templateUrl: './signup-applications.component.html',
  styleUrl: './signup-applications.component.scss'
})
export class SignupApplicationsComponent extends BaseComponent {

  applicationService = inject(SignupApplicationService);
  apiService = inject(ApiService);


  applicationsState = new State<GetCompanyApplicationListResponse>();
  acceptApplicationState = new State<GenericResponse>();
  rejectApplicationState = new State<GenericResponse>();

  columnDefs: ColumnDef[] = [
    {title: 'Full name', type: 'text', displayTemplate: '$firstName $lastName', alignment: 'center'},
    {title: 'Email', type: 'text', displayTemplate: '$email', alignment: 'center'},
    {title: 'Phone Number', type: 'text', displayTemplate: '$phoneNumber', alignment: 'center'},
    {title: 'Company / Individual', type: 'text', displayTemplate: '$companyName', alignment: 'center'},
    {title: 'Company Type', type: 'text', displayTemplate: '$companyType', alignment: 'center'},
    {
      title: 'Status',
      type: 'badge',
      displayTemplate: '$companyApplicationStatus',
      alignment: 'center',
      configOfBadge: {
        properties: [
          {
            data: 'Pending',
            displayText: 'Pending',
            backgroundColorClass: 'bg-warning-50',
            borderColorClass: 'border-transparent',
            textColorClass: 'text-warning-500'
          },
          {
            data: 'Open',
            displayText: 'Open',
            backgroundColorClass: 'bg-info-50',
            borderColorClass: 'border-transparent',
            textColorClass: 'text-info-500'
          },
          {
            data: 'Completed',
            displayText: 'Completed',
            backgroundColorClass: 'bg-success-50',
            borderColorClass: 'border-transparent',
            textColorClass: 'text-success-500'
          },
          {
            data: 'Rejected',
            displayText: 'Rejected',
            backgroundColorClass: 'bg-error-50',
            borderColorClass: 'border-transparent',
            textColorClass: 'text-error-500'
          }
        ]
      }
    },
    {
      title: 'Actions', type: 'actions', alignment: 'center', configOfActions: {
        threeDotMenuActions: [
          {
            label: 'Accept',
            actionType: 'accept',
          },
          {
            label: 'Reject',
            actionType: 'reject',
          }
        ]
      }
    }
  ];


  getApplications(event: TableStateEvent) {
    let request: GetCompanyApplicationListParams = {
      pageNumber: event.paginationEvent?.pageNumber,
      pageSize: event.paginationEvent?.pageSize
    };
    this.executeRequest<GetCompanyApplicationListResponse>({
      state: this.applicationsState,
      request: this.apiService.getCompanyApplicationList(request),
      onSuccess(response) {
      },
    });
  }

  onActionClicked($event: TableActionEvent) {
    let item: GetCompanyApplicationListItem = $event.item;
    switch ($event.actionType) {
      case 'accept':
        this.onAcceptClicked(item.id);
        break;
      case 'reject':
        this.onRejectClicked(item.id);
        break;
    }
  }

  onAcceptClicked(companyApplicationId: string) {
    this.alertDialog.show('Approve', 'Are you sure you want to accept this application?')
      .subscribe(action => {
        if (action) {
          const request: AcceptSignupApplicationRequest = {
            companyApplicationId
          };
          this.executeRequest<GenericResponse>({
            state: this.acceptApplicationState,
            request: this.applicationService.approveApplication(request),
            handleSuccess: true,
            onSuccess: response => {
              this.getApplications(Constants.defaultTableStateEvent);
            }
          });
        }
      });
  }

  onRejectClicked(companyApplicationId: string) {
    this.alertDialog.show('Reject', 'Are you sure you want to reject this application?')
      .subscribe(action => {
          if (action) {
            const request: RejectSignupApplicationRequest = {
              companyApplicationId
            };
            this.executeRequest<GenericResponse>
            ({
              state: this.rejectApplicationState,
              request: this.applicationService.rejectApplication(request),
              handleSuccess: true,
              onSuccess: response => {
                this.getApplications(Constants.defaultTableStateEvent);
              }
            });
          }
        }
      );
  }


}














