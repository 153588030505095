import {Component, inject} from '@angular/core';
import {AppSvgIconComponent} from "../../shared/components/app-svg-icon/app-svg-icon.component";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {TokenStoreService} from '../../core/services/token/token-store.service';
import {BaseComponent} from "../../shared/base/base-component";
import {NoDataComponent} from "../../shared/components/no-data/no-data.component";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [ NoDataComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent extends BaseComponent {

}
