import {PagingEvent} from "../../shared/components/_base/base-table/base-table.component";
import {TableStateEvent} from "../../shared/components/data-table/data-table.component";

export class Constants {

  static defaultDialogConfig = {
    // minWidth: '200px',
    // maxWidth: '600px',
    panelClass: ['w-full', 'sm:w-4/5', 'md:w-3/5', 'lg:w-2/5'],
    maxHeight: '95vh',
  };

  static defaultPaginationParams: PagingEvent = {
    pageNumber: 1,
    pageSize: 10
  };

  static defaultTableStateEvent: TableStateEvent = {
    paginationEvent: {
      pageNumber: 1,
      pageSize: 10
    },
    tableSortEvent: {
      key: undefined,
      direction: undefined
    }
  };

}
