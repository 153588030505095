<div class="app-container !p-0">
  <div class="app-header">
    <app-outline-text-input [iconSrc]="'assets/icons/magnify.svg'" placeholder="Search..." type="search"
                            [fullWidth]="false"></app-outline-text-input>
  </div>
  <app-data-table
    class="app-table"
    [state]="companyListState"
    [columnDefs]="columnDefs"
    (onActionClicked)="onActionClicked($event)"
    (tableStateChanged)="getCompanyList($event)">
  </app-data-table>

</div>
